import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
interface UserState {
    id?: number,
    email: string,
    token: string
}

// Define the initial state using that type
const initialState: UserState = {
    id: 0, email: "", token: ""
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        register: (state, action: PayloadAction<UserState>) => {
            state.token += action.payload.token
        },
        authenticate: (state, action: PayloadAction<UserState>) => {
            state.token += action.payload.token
        },
    },
})

export const { register, authenticate } = userSlice.actions
export default userSlice.reducer