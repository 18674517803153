import React from 'react'
import { Product } from '../../models/Product';
import './ProductList.css';
import { Button } from 'react-bootstrap'
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { updateOrder } from '../../store/slices/orderSlice';
import { useTranslation } from 'react-i18next';
import { CarouselStep } from '../../utils/CarouselSteps';

interface Props {
    productList: Product[]
}

const ProductList: React.FunctionComponent<Props> = ({ productList }: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const order = useAppSelector((rootState) => rootState.order);

    const onProductSelection = (product: Product | null | undefined) => {
        if (!product) return;
        let orderState = { ...order };
        orderState.product = product;
        if (product.id === 2) {
            orderState.carouselIndex = CarouselStep.ProfessionalList;
        } else {
            orderState.carouselIndex = CarouselStep.ReferenceYear;
        }
        dispatch(updateOrder(orderState));
    }

    const getProductDescription = (descriptionStr: string) => {
        if (!descriptionStr) return null;
        const splitted = descriptionStr.split("|");
        return splitted.map((item, index) => {
            return <li key={index}>{item}</li>;
        });
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h1>{t('order.productlist.chooseProduct')}</h1>
                    <h5>{t('order.productlist.pricingInfo1')}</h5>
                </div>
            </div>
            <div className="row gx-3">
                {productList.map((product, index) => {
                    return (
                        <div key={product.id} className="col-sm-12 col-lg-4">
                            <div className={"pricingTable " + (index == 1 ? "pricingTablePromoted" : "")}>
                                <div className="pricingTable-header">
                                    <h3 className="title">{product.name}</h3>
                                    <h5>{t(product.pricingTableSubtitleLanguageKey)}</h5>
                                    <hr />
                                    <p className="price-value">{product.basePrice} <span className="value-bg">CHF {product.basePrice}</span></p>
                                    <h5><strong>{t('order.productlist.perYear')}</strong></h5>
                                    <hr />
                                </div>
                                <ul className="pricing-content">
                                    {getProductDescription(t(product.pricingTableDescriptionLanguageKey))}
                                </ul>
                                <hr />
                                <Button variant="primary" size="lg" onClick={() => onProductSelection(product)}>{t("misc.choose")}</Button>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='row'>
                <div className='col-12'>
                    <p>{t('order.productlist.pricingInfo2')}</p>
                </div>
            </div>
        </>
    )
}

export default ProductList