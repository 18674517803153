import React, { useEffect, useState } from 'react'
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
import { supportedLanguages } from '../../language/supportedLanguages';

interface Props {

}

const Navigation: React.FunctionComponent<Props> = ({ }: Props) => {
    const { t, i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [navIsOpen, setNavIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const langParam = searchParams.get('lang') || searchParams.get('lang');
        changeLanguage(langParam);
    }, []);

    const changeLanguage = (lang: string | null) => {
        if (!lang || !supportedLanguages.includes(lang.toLowerCase())) {
            lang = 'de';
        }
        i18n.changeLanguage(lang.toLowerCase());

        const params = new URLSearchParams(searchParams.toString());
        if (lang.toLowerCase() === 'de') {
            params.delete('lang');
        } else {
            params.set('lang', lang.toLowerCase());
        }
        setSearchParams(params);
    };

    const getLanguageFlag = (language: string) => {
        return "https://esg2go.org/wp-content/plugins/sitepress-multilingual-cms/res/flags/" + language + ".png";
    }

    return (
        <nav className="navbar navbar-expand-lg fixed-top" id="main-nav">
            <div className="container-lg">
                <a target="_blank" href="https://esg2go.org/">
                    <img alt="logo" className="logo" src="https://esg2go.org/wp-content/uploads/2022/02/esg2go_Logo-nobyline-250.jpg" />
                </a>
                <button className="navbar-toggler" onClick={() => setNavIsOpen(!navIsOpen)}>
                    <FontAwesomeIcon icon={faBarsStaggered} />
                </button>
                <div className={"collapse navbar-collapse" + (navIsOpen ? " show" : "")} id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href="https://esg2go.org/angebot-preise/">{t("layout.navigation.prices")}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href="https://esg2go.org/pros-partner/">{t("layout.navigation.partner")}</a>
                        </li> 
                        <NavDropdown id="" className="float-end" title={<>{t("layout.navigation.about")} <FontAwesomeIcon icon={faChevronDown} /></>}>
                            <NavDropdown.Item href='https://esg2go.org/community/'>{t("layout.navigation.community")}</NavDropdown.Item>
                            <NavDropdown.Item href='https://esg2go.org/transparenz/'>{t("layout.navigation.transparency")}</NavDropdown.Item>
                            <NavDropdown.Item href="https://esg2go.org/datensicherheit/">{t("layout.navigation.datasecurity")}</NavDropdown.Item>
                            <NavDropdown.Item href="https://esg2go.org/team-geschichte/">{t("layout.navigation.team")}</NavDropdown.Item>
                            <NavDropdown.Item href="https://esg2go.org/blog/">{t("layout.navigation.blog")}</NavDropdown.Item>
                            <NavDropdown.Item href="https://esg2go.org/medienspiegel/">{t("layout.navigation.media")}</NavDropdown.Item>
                            <NavDropdown.Item href="https://esg2go.org/faq/">{t("layout.navigation.faq")}</NavDropdown.Item>
                        </NavDropdown>
                        <li className="nav-item highlighted">
                            <a className="nav-link" aria-current="page" target="_blank" href="https://www.esg2go.net/esg2go/">{t("layout.navigation.enterData")}</a>
                        </li>
                        <NavDropdown id="" className="float-end" title={
                            <><img className="language-flags" src={getLanguageFlag(i18n.language)} /> {i18n.language.toUpperCase()} <FontAwesomeIcon icon={faChevronDown} /></>
                        } onSelect={(e) => changeLanguage(e)}>
                            {i18n.language !== "de" ? <NavDropdown.Item eventKey="de"><img className="language-flags" src={getLanguageFlag("de")} /> DE</NavDropdown.Item> : null}
                            {i18n.language !== "en" ? <NavDropdown.Item eventKey="en"><img className="language-flags" src={getLanguageFlag("en")} /> EN</NavDropdown.Item> : null}
                            {i18n.language !== "fr" ? <NavDropdown.Item eventKey="fr"><img className="language-flags" src={getLanguageFlag("fr")} /> FR</NavDropdown.Item> : null}
                            {i18n.language !== "it" ? <NavDropdown.Item eventKey="it"><img className="language-flags" src={getLanguageFlag("it")} /> IT</NavDropdown.Item> : null}
                        </NavDropdown>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navigation;