import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react'
import { Alert, } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Navigation from '../components/Layout/Navigation';
import { useAppSelector } from '../store/hooks';

interface Props {
    children?: ReactNode
}

const Layout: React.FunctionComponent<Props> = ({ children }: Props) => {
    const error = useAppSelector((rootState) => rootState.error);
    const loadNav = true; // (process.env.REACT_APP_IS_IN_IFRAME !== 'true');
    const { t } = useTranslation();

    return (
        <>
            {loadNav ? <Navigation /> : null}
            <main>
                {error.errorMsg ?
                    <div className="container-md">
                        <Alert className='mt-4' variant="danger" dangerouslySetInnerHTML={{ __html: error.errorMsg}}></Alert>
                    </div>
                    :
                    children
                }
            </main>
            <footer>
                <div className="container-md">
                    <div className="col-12">
                        <span>
                            <FontAwesomeIcon icon={faAngleLeft} className="me-3" /><a href='https://esg2go.org'>{t("misc.backToWebsite")}</a>
                        </span>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Layout;