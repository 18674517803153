import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../store/hooks';
import { setLoading } from '../store/slices/loaderSlice';
import OrderService from "../services/order.service";
import Layout from './Layout';
import './OrderStatus.css';
import { setError } from '../store/slices/errorSlice';

interface Props {
    isSuccess: boolean
}

const OrderStatus: React.FunctionComponent<Props> = ({ isSuccess }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const [transactionId, setTransactionId] = useState<string | null>(null);

    useEffect(() => {
        const transactionIdParam = searchParams.get("transactionUuid")
        setTransactionId(transactionIdParam);
        if (transactionIdParam) {
            dispatch(setLoading(false));
        }
    }, [])

    if (!transactionId) {
        isSuccess = false;
    } else {
        if (isSuccess) {
            OrderService.updateOrder(transactionId, "de").then(() => {
                dispatch(setError(""));
            }).catch((e: Error) => {
                dispatch(setError(t("misc.genericError")));
                isSuccess = false;
            });
        }
    }

    const showSuccessContent = () => {
        return (
            <>
                <h5 dangerouslySetInnerHTML={{ __html: t("orderStatus.infoSuccess") }}></h5>
                <svg className="order-status-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="order-status-svg-cirlce" cx="26" cy="26" r="25" fill="none" />
                    <path className="order-status-svg-symbol" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
            </>
        );
    }

    const showFailedContent = () => {
        return (
            <>
                <h5 dangerouslySetInnerHTML={{ __html: t("orderStatus.infoFailed") }}></h5>
                <svg className="order-status-svg-red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="order-status-svg-cirlce-red" cx="26" cy="26" r="25" fill="none" />
                    <path className="order-status-svg-symbol-red" fill="none" d="M16 16 36 36 M36 16 16 36" />
                </svg>
            </>
        );
    }

    return (
        <Layout>
            <div className="container-md">
                <div className="row">
                    <div className="col-12 pt-4 text-center">
                        <h1>
                            {t('orderStatus.title')}
                            <span className="normal-case">
                                {transactionId && transactionId.length > 10 ?
                                    <span className="small-text"><br />{transactionId}</span>
                                    : transactionId}
                            </span>
                        </h1>
                        {isSuccess ? showSuccessContent() : showFailedContent()}
                        <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("orderStatus.footer") }}></p>
                    </div>
                </div>
            </div>
        </Layout>
    );

}

export default OrderStatus;