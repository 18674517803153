import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { CarouselStep } from '../../utils/CarouselSteps';
import OrderBackButton from './OrderBackButton';
import OrderService from "../../services/order.service";
import { setError } from '../../store/slices/errorSlice';
import PricingTable from './PricingTable';

interface Props {
}

const InitPayment: React.FunctionComponent<Props> = ({ }: Props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const order = useAppSelector((rootState) => rootState.order);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [payrexxOrderLink, setPayrexxOrderLink] = useState<string>("");

    useEffect(() => {
        if (!order.user || order.user?.id === "") return;
        if (!order.contactInfo || order.contactInfo?.id === 0) return;
        if(order.carouselIndex !== CarouselStep.InitPayment) return;
        setIsLoading(true);
        
        OrderService.intitOrder(order, i18n.language).then((response) => {
            setIsLoading(false);
            if (response.payrexxGatewayLink) {
                dispatch(setError(""));
                setPayrexxOrderLink(response.payrexxGatewayLink);
            } else {
                dispatch(setError(t("misc.genericError")));
            }
        }).catch((e: Error) => {
            dispatch(setError(t("misc.genericError")));
            setIsLoading(false);
        });


        //setIsLoading(false);
    }, [order.user?.id, order.contactInfo?.id, order.carouselIndex]);

    return (
        <div className="row">
            <div className="col-12">
                <h1>{t('order.initPayment.title')}</h1>
                <h5>{t('order.initPayment.info')}</h5>

                <PricingTable />

                <OrderBackButton toIndex={CarouselStep.Discount} />
                <a className={"btn btn-primary mt-4" + (isLoading ? " disabled" : "")} href={(isLoading || !payrexxOrderLink ? "#" : payrexxOrderLink)} role="button">
                    {t("misc.order")}
                    {isLoading ?
                        <span> <Spinner
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /></span> : null
                    }
                </a>
            </div>
        </div>
    );
}

export default InitPayment;