import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { setLoading } from '../store/slices/loaderSlice';
import { setError } from '../store/slices/errorSlice';
import { updateOrder } from '../store/slices/orderSlice';
import { CarouselStep } from '../utils/CarouselSteps';
import { Product } from '../models/Product';
import { Professional } from '../models/Professional';
import ProductDataService from '../services/product.dataservice';
import ProfessionalDataService from '../services/professional.dataservice';
import ProductList from '../components/Order/ProductList';
import ProfessionalList from '../components/Order/ProfessionalList';
import ReferenceYear from '../components/Order/ReferenceYear';
import DiscountSelection from '../components/Order/DiscountSelection';
import UserAuthentication from '../components/Order/UserAuthentication';
import InitPayment from "../components/Order/InitPayment";
import { useTranslation } from 'react-i18next';
import Layout from './Layout';
import ContactInfoSelection from '../components/Order/ContactInfoSelection';

const Order: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const order = useAppSelector((rootState) => rootState.order);
    const [productList, setProductList] = useState<Product[]>([]);
    const [professionalList, setProfessionalList] = useState<Professional[]>([]);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // query-parameter auslesen
        const productParam = searchParams.get('product');
        const codeParam = searchParams.get('code');
        const languageParam = searchParams.get('language');
        const yearParam = searchParams.get('year');
        const professionalParam = searchParams.get('professionalId');

        // sprache setzen
        if (languageParam) {
            i18n.changeLanguage(languageParam.toLowerCase());
        }

        // vorabwerte speichern
        const preselectedValues = {
            productId: productParam ? parseInt(productParam) : null,
            discountCode: codeParam ? codeParam : null,
            referenceYear: yearParam ? parseInt(yearParam) : null,
            professionalId: professionalParam ? parseInt(professionalParam) : null,
        };

        const loadData = async () => {
            dispatch(setLoading(true));

            try {
                const response = await fetch("referenceYear.json");
                const data = await response.json();
                const validYears = data.years;
    
                // jahr validieren
                if (preselectedValues.referenceYear && !validYears.includes(preselectedValues.referenceYear)) {
                    dispatch(setError(t("misc.invalidYear")));
                    preselectedValues.referenceYear = null;
                }

                // produkte laden
                const productResponse = await ProductDataService.getAll();
                setProductList(productResponse.data);

                let product = null;
                if (preselectedValues.productId) {
                    product = productResponse.data.find(p => p.id === preselectedValues.productId);
                    if (product) {
                        dispatch(updateOrder({ product }));
                    } else {
                        dispatch(setError(t("misc.invalidProduct")));
                    }
                }

                let nextCarouselIndex = CarouselStep.ProductList;

                if (product) {
                    if (product.id === 2) {
                        // produkt erfordert professional-auswahl
                        // professionals laden
                        const profResponse = await ProfessionalDataService.getAll();
                        setProfessionalList(profResponse.data);

                        if (preselectedValues.professionalId !== null && preselectedValues.professionalId !== undefined) {
                            const professional = profResponse.data.find(p => p.id === preselectedValues.professionalId);
                            if (professional || preselectedValues.professionalId === 0) {
                                dispatch(updateOrder({ professionalId: preselectedValues.professionalId }));
                                nextCarouselIndex = CarouselStep.ReferenceYear;
                            } else {
                                dispatch(setError(t("misc.invalidProfessional")));
                                nextCarouselIndex = CarouselStep.ProfessionalList;
                            }
                        } else {
                            nextCarouselIndex = CarouselStep.ProfessionalList;
                        }                        
                    } else {
                        // produkt erfordert keine professional-auswahl
                        nextCarouselIndex = CarouselStep.ReferenceYear;
                    }

                    // weiter zu referenceYear nur, wenn professional-auswahl abgeschlossen ist (also hier nur für produkt 2)
                    if (nextCarouselIndex === CarouselStep.ReferenceYear) {
                        if (preselectedValues.referenceYear) {
                            dispatch(updateOrder({ referenceYear: preselectedValues.referenceYear }));
                            nextCarouselIndex = CarouselStep.OrderUser;
                        }
                    }

                    // rabattcode setzen, validierung erfolgt später
                    if (preselectedValues.discountCode) {
                        dispatch(updateOrder({ discountCode: { code: preselectedValues.discountCode } }));
                    }
                }

                // karussell-Index setzen, um schritte zu überspringen
                dispatch(updateOrder({ carouselIndex: nextCarouselIndex }));
                dispatch(setError(""));
            } catch (e) {
                dispatch(setError(t("misc.genericError")));
            } finally {
                dispatch(setLoading(false));
            }
        };

        loadData();
    }, []);

    useEffect(() => {
        onSlideCarousel(order.carouselIndex);
    }, [order.carouselIndex]);

    const onSlideCarouselManual = (selectedIndex: number, e: Record<string, unknown> | null): void => {
        onSlideCarousel(selectedIndex);
    };

    const onSlideCarousel = (slideIndex: number): void => {
        window.scrollTo(0, 0);

        switch (slideIndex) {
            case CarouselStep.ProductList:
                if (productList.length === 0) {
                    dispatch(setLoading(true));
                    ProductDataService.getAll().then((response) => {
                        setProductList(response.data);
                        dispatch(setLoading(false));
                        dispatch(setError(""));
                    }).catch((e: Error) => {
                        dispatch(setError(t("misc.genericError")));
                        dispatch(setLoading(false));
                    });
                }
                break;
            case CarouselStep.ProfessionalList:
                if (professionalList.length === 0) {
                    ProfessionalDataService.getAll().then((response) => {
                        setProfessionalList(response.data);
                        dispatch(setError(""));
                    }).catch((e: Error) => {
                        dispatch(setError(t("misc.genericError")));
                    });
                }
                break;
            default:
                break;
        }
    };

    return (
        <Layout>
            <div className="container-md">
                <Carousel
                    activeIndex={order.carouselIndex}
                    onSelect={onSlideCarouselManual}
                    variant={"dark"}
                    controls={false}
                    indicators={false}
                    interval={null}
                    keyboard={false}
                    touch={false}
                    wrap={false}
                >
                    <Carousel.Item>
                        <ProductList productList={productList} />
                    </Carousel.Item>
                    <Carousel.Item>
                        <ProfessionalList professionalList={professionalList} />
                    </Carousel.Item>
                    <Carousel.Item>
                        <ReferenceYear />
                    </Carousel.Item>
                    <Carousel.Item>
                        <UserAuthentication />
                    </Carousel.Item>
                    <Carousel.Item>
                        <ContactInfoSelection />
                    </Carousel.Item>
                    <Carousel.Item>
                        <DiscountSelection />
                    </Carousel.Item>
                    <Carousel.Item>
                        <InitPayment />
                    </Carousel.Item>
                </Carousel>
            </div>
        </Layout>
    );
};

export default Order;
