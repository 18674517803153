import { AxiosResponse } from "axios";
import {http, httpLocalized} from "../http-common";
import { AuthenticateRequest } from "../models/AuthenticateRequest";
import { AuthenticateReponse } from "../models/AuthenticateResponse";
import {ContactInfo} from "../models/ContactInfo";
import { Order } from "../models/Order";
import { RegisterRequest } from "../models/RegisterRequest";
import { ResponseWithPossibleError } from "../models/ResponseWithPossibleError";

class ContactInfoDataService {
    async getAll(order : Order) {
        const config = {
            headers: { Authorization: `Bearer ${order.user?.jwtToken}` }
        };
        return await http().get<Array<ContactInfo>>("/contactInfo", config);
    }

    async delete(id: number, order : Order) {
        const config = {
            headers: { Authorization: `Bearer ${order.user?.jwtToken}` }
        };
        return await http().delete<Array<ContactInfo>>("/contactInfo/" + id, config);
    }

    async put(registerRequestModel: RegisterRequest, order: Order, lang: string) {
        const config = {
            headers: { Authorization: `Bearer ${order.user?.jwtToken}` }
        };
        const { data } = await httpLocalized(lang).put<AuthenticateRequest, AxiosResponse<ResponseWithPossibleError>>("/contactInfo/" + registerRequestModel.contactInfoId, registerRequestModel, config);
        return data;
    }
}
export default new ContactInfoDataService();