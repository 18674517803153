import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner, Button, Form, InputGroup, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CarouselStep } from '../../utils/CarouselSteps';
import OrderBackButton from './OrderBackButton';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { updateOrder } from '../../store/slices/orderSlice';
import DiscountCodeDataService from '../../services/discountCode.dataservice';
import { setError } from '../../store/slices/errorSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import PricingTable from './PricingTable';

interface Props { }

const DiscountSelection: React.FunctionComponent<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const order = useAppSelector((rootState) => rootState.order);
    const [discountCode, setDiscountCode] = useState<string>("");
    const [validationStatus, setValidationStatus] = useState<string>("");
    const [isCurrentlyChecking, setIsCurrentlyChecking] = useState<boolean>(false);
    const [hasOrders, setHasOrders] = useState<boolean>(false);

    useEffect(() => {
        if (!order.user || order.user?.id === "") return;
        if (!order.contactInfo || order.contactInfo?.id === 0) return;
        if (!order.product || order.product?.id === 0) return;
        if (order.carouselIndex !== CarouselStep.Discount) return;
    
        if (order.discountCode && order.discountCode.code) {
            setDiscountCode(order.discountCode.code);
            setValidationStatus("");
            dispatch(updateOrder({ discountCode: null }));
            onDiscountCodeApply(order.discountCode.code); // Rabattcode validieren mit direkter Übergabe
        } else {
            setDiscountCode("");
            setValidationStatus("");
        }
    
        checkForRecurringCustomer(order.contactInfo.id);
    }, [order.product, order.user?.id, order.contactInfo?.id, order.carouselIndex]);    
    

    const setInvalidState = () => {
        setIsCurrentlyChecking(false);
        setValidationStatus("is-invalid");
    }

    const checkForRecurringCustomer = (contactInfoId: number | null | undefined) => {
        if (contactInfoId) {
            DiscountCodeDataService.checkForRecurringCustomer(contactInfoId).then((response) => {
                setHasOrders(response.data);
                  
            }).catch((e: Error) => {
                dispatch(setError(t("misc.genericError")));
                setInvalidState();
            });
        }
    }

    const onDiscountCodeApply = (codeToValidate?: string) => {
        const code = codeToValidate || discountCode;
        setIsCurrentlyChecking(true);
        setValidationStatus("");
        if (order.product && order.product.id && order.contactInfo && order.contactInfo.id && code) {
            DiscountCodeDataService.get(code, order.product.id, order.contactInfo.id).then((response) => {
                if (response.data.id !== 0 && response.data.code) {
                    setIsCurrentlyChecking(false);
                    setValidationStatus("is-valid");
                    dispatch(updateOrder({ ...order, discountCode: response.data }));
                } else {
                    setInvalidState();
                }
                dispatch(setError(""));
            }).catch((e: Error) => {
                dispatch(setError(t("misc.genericError")));
                setInvalidState();
            });
        } else {
            setInvalidState();
        }
    }    

    const onDiscountCodeRemove = () => {
        setIsCurrentlyChecking(false);
        setValidationStatus("");
        dispatch(updateOrder({ ...order, discountCode: null }));
    }

    const onDiscountCodeSelection = () => {
        let orderState = { ...order };
        orderState.carouselIndex = CarouselStep.InitPayment;
        dispatch(updateOrder(orderState));
    }

    const hasDiscountCode = (): boolean => {
        if (!discountCode) return false;
        return validationStatus === "is-valid";
    }

    return (
        <div className="row">
            <div className="col-12">
                <h1>{t("order.discount.chooseDiscount")}</h1>
                <h5 dangerouslySetInnerHTML={{ __html: t("order.discount.discountInfo") }} className="mb-4"></h5>
                {hasOrders ?<Alert className='mb-4' variant="success">{t("order.discount.recurringCustomer")}</Alert> : null }
                <Row>
                    <Col xs="6" md="4">
                        <InputGroup className="mb-2">
                            <InputGroup.Text>#</InputGroup.Text>
                            <Form.Control
                                value={discountCode}
                                id="order-discount"
                                maxLength={11}
                                type="text"
                                placeholder={t("order.discount.discountPlaceholder")}
                                className={validationStatus}
                                onChange={(e) => setDiscountCode(e.target.value)}
                                readOnly={isCurrentlyChecking || hasDiscountCode()}
                                disabled={isCurrentlyChecking || hasDiscountCode()} />
                            <Form.Control.Feedback type="invalid">
                                {t("order.discount.codeInvalid")}
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">
                                {t("order.discount.codeValid")}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                    <Col xs="auto">
                        {hasDiscountCode() ?
                            <Button variant="danger" onClick={() => onDiscountCodeRemove()}>×</Button>
                            :
                            <Button variant="primary" onClick={() => onDiscountCodeApply()} disabled={isCurrentlyChecking}>
                                {t("misc.apply")}
                                {isCurrentlyChecking ?
                                    <span> <Spinner
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /></span> : null
                                }
                            </Button>
                        }
                    </Col>
                </Row>

                <PricingTable />

                <OrderBackButton toIndex={CarouselStep.ContactInfoSelection} />
                <Button variant="primary" onClick={() => onDiscountCodeSelection()} className="mt-4">
                    {t("misc.next")} <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>
        </div >);
}

export default DiscountSelection;