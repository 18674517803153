import React, { useState } from 'react'
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';
import { calculateSubTotalPrice, calculateTax, calculateTotalPrice } from '../../utils/priceCalculation';

interface Props {

}

const PricingTable: React.FunctionComponent<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const order = useAppSelector((rootState) => rootState.order);

    const hasDiscountCode = (): boolean => {
        return order.discountCode?.discount != null;
    };    

    return (
        <Table bordered responsive size="md" className='mt-3 col-12 col-md-4'>
            <thead>
                <tr>
                    <th></th>
                    <th className='text-end'>{t("misc.price")}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{t("misc.product")}: {order.product?.name}</td>
                    <td className='text-end'>CHF {order.product?.basePrice.toFixed(2)}</td>
                </tr>
                {
                    hasDiscountCode() ?
                        <>
                            <tr>
                                <td className='fst-italic'>{t("order.discount.discountPlaceholder")}: {order.discountCode?.code} ({order.discountCode?.name})</td>
                                <td className='text-end fst-italic'>-{order.discountCode!.discount!.toFixed(2)} %</td>
                            </tr>
                            <tr>
                                <td>{t("misc.subtotal")}</td>
                                <td className='text-end'>CHF {calculateSubTotalPrice(order.product?.basePrice, order.discountCode?.discount)}</td>
                            </tr>
                        </>
                        : null
                }
                <tr>
                    <td>{t("misc.taxRate")} {process.env.REACT_APP_TAX_RATE}%</td>
                    <td className='text-end'>CHF {calculateTax(order.product?.basePrice, order.discountCode?.discount, parseFloat(process.env.REACT_APP_TAX_RATE ? process.env.REACT_APP_TAX_RATE : "0.0"))}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr className='bg-lightcolor fw-bold'>
                    <td>{t("misc.total")}</td>
                    <td className='text-end'>CHF {calculateTotalPrice(order.product?.basePrice, order.discountCode?.discount, parseFloat(process.env.REACT_APP_TAX_RATE ? process.env.REACT_APP_TAX_RATE : "0.0"))}</td>
                </tr>
            </tfoot>
        </Table>
    );
}

export default PricingTable;