import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import orderReducer from './slices/orderSlice'
import loaderReducer from './slices/loaderSlice'
import errorSlice from './slices/errorSlice'

export const rootStore = configureStore({
  reducer: {
    loader: loaderReducer,
    order: orderReducer,
    user: userReducer,
    error: errorSlice
  },
})

export type RootState = ReturnType<typeof rootStore.getState>
export type AppDispatch = typeof rootStore.dispatch