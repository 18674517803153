import { http } from "../http-common";
import { DiscountCode } from "../models/DiscountCode";

class DiscountCodeDataService {
    async get(code: string, productId: number, contactInfo: number) {
        return await http().get<DiscountCode>(`/discountcodes/${code}/product/${productId}/contactInfo/${contactInfo}`);
    }

    async checkForRecurringCustomer(contactInfoId: number) {
        return await http().get<boolean>(`/discountcodes/checkForRecurringCustomer/${contactInfoId}`);
    }
}
export default new DiscountCodeDataService();