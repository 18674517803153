import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GlobalError } from '../../models/GlobalError'

// Define the initial state using that type
const initialState: GlobalError = {
    errorMsg: ""
}

export const errorSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<string>) => {
            state.errorMsg = action.payload;
        }
    },
})

export const { setError } = errorSlice.actions
export default errorSlice.reducer