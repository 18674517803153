import React, { useState, ChangeEvent, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { updateOrder } from '../../store/slices/orderSlice';
import { useTranslation } from 'react-i18next';
import OrderBackButton from './OrderBackButton';
import { CarouselStep } from '../../utils/CarouselSteps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface Props { }

const ReferenceYear: React.FunctionComponent<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const order = useAppSelector((rootState) => rootState.order);
    const [referenceYear, setReferenceYear] = useState<number>(new Date().getFullYear() - 1);
    const [allYears, setAllYears] = useState<JSX.Element[]>([]);

    useEffect(() => {
        if (allYears.length === 0) yearList();
    }, [allYears]);

    const onDropDownChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        if (selectedValue) {
            const year: number = +selectedValue;
            if (year) {
                setReferenceYear(year);
            }
        }
    }

    const onReferenceYearSelection = () => {
        let orderState = { ...order };
        orderState.referenceYear = referenceYear;
        orderState.carouselIndex = CarouselStep.OrderUser;
        dispatch(updateOrder(orderState));
    }

    const yearList = () => {
        fetch("referenceYear.json")
            .then((r) => r.json())
            .then((data) => {
                var years: JSX.Element[] = [];
                for (var i = 0; i < data.years.length; i++) {
                    years.push(<option value={data.years[i].toString()} key={data.years[i]}>{data.years[i]}</option>);
                }
                setAllYears(years);
            });
    }

    return (
        <div className="row">
            <div className="col-12">
                <h1>{t("order.referenceYear.chooseReferenceYear")}</h1>
                <h5 className="mb-4">{t("order.referenceYear.referenceYearInfo")}</h5>

                <div className="row">
                    <div className="mt-4 col-12 col-lg-3">
                        <Form.Select onChange={(e) => onDropDownChange(e)} value={referenceYear}>
                            {allYears}
                        </Form.Select>
                    </div>
                </div>
                <OrderBackButton toIndex={order.product?.id === 2 ? CarouselStep.ProfessionalList : CarouselStep.ProductList} />
                <Button variant="primary" onClick={() => onReferenceYearSelection()} className="mt-4">
                    {t("misc.next")} <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>
        </div>
    );
}

export default ReferenceYear