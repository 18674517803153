import axios from "axios";

export function http() {
    return httpLocalized("");
}

export function httpLocalized(lang: string) {
    if(!lang) lang = "de";
    return(
        axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Accept-Language": lang
            }
        })
    )
}

