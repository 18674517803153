import { AxiosResponse } from "axios";
import { httpLocalized } from "../http-common";
import { InitOrder } from "../models/InitOrder";
import { Order } from "../models/Order";

class OrderService {
    async intitOrder(order : Order, lang : string) {
        const config = {
            headers: { Authorization: `Bearer ${order.user?.jwtToken}` }
        };

        const initOrderObj : InitOrder = {
            productId: order.product?.id, 
            professionalId: order.professionalId, 
            contactInfoId: order.contactInfo?.id, 
            referenceYear: order.referenceYear,
            discountCode: order.discountCode?.code,
            referral: document.referrer
        }

        const { data } = await httpLocalized(lang).post<InitOrder, AxiosResponse<InitOrder>>("/orders", initOrderObj, config);
        return data;
    }

    async updateOrder(orderId : string, lang : string) {
        const bearerToken = localStorage.getItem("order-esg2go-user");
        const config = {
            headers: { Authorization: `Bearer ${bearerToken}` }
        };

        const { data } = await httpLocalized(lang).get<AxiosResponse>(`/orders/finalize/${orderId}`, config);
        return data;
    }
}
export default new OrderService();