export const calculateSubTotalPrice = (price: number | undefined, discount: number | undefined) => {
    if (price !== undefined && discount !== undefined) {
        let brutto = price - (price * discount / 100);
        return brutto.toFixed(2);
    }
    return price ? price.toFixed(2) : '0.00';
};

export const calculateTax = (price: number | undefined, discount: number | undefined, taxRate: number) => {
    if (price && taxRate) {
        let brutto: number = price;
        if (discount) {
            brutto = price - (price * discount / 100);
        }
        const tax: number = brutto * taxRate / 100;
        return tax.toFixed(2);
    }
    return price;
}

export const calculateTotalPrice = (price: number | undefined, discount: number | undefined, taxRate: number) => {
    if (price && taxRate) {
        let brutto: number = price;
        if (discount) {
            brutto = price - (price * discount / 100);
        }
        const netto: number = brutto + (brutto * taxRate / 100);
        return netto.toFixed(2);
    }
    return price;
}