import React, { useRef, useState } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha";
import AuthDataService from "../../services/auth.dataservice";
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useAppDispatch } from '../../store/hooks';
import { RequestPasswordResetRequest, defaultRequestPasswordResetRequest } from '../../models/RequestPasswordResetRequest';
import { hasAnyFormErrors, validatePasswordResetRequestForm } from '../../utils/formValidation';
import { setError } from '../../store/slices/errorSlice';

interface Props {
    reCaptchaKey: string
}

const ResetRequestForm: React.FunctionComponent<Props> = ({ reCaptchaKey }: Props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const [captchaToken, setCaptchaToken] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPasswordResetRequestet, setIsPasswordResetRequestet] = useState<boolean>(false);
    const captchaRef = useRef<ReCAPTCHA>(null);

    const formik = useFormik<RequestPasswordResetRequest>({
        initialValues: defaultRequestPasswordResetRequest,
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        onSubmit: (formValues, { setErrors }) => {
            const errors = validatePasswordResetRequestForm(formValues);
            if (hasAnyFormErrors(errors)) {
                setErrors(errors);
                return;
            }
            setIsLoading(true);
            AuthDataService.requestPasswordReset({ ...formValues, captchaToken }, i18n.language).then((response) => {
                if (!response.hasError) {
                    setErrorMessage("");
                    setIsLoading(false);
                    setIsPasswordResetRequestet(true);
                } else {
                    setErrorMessage(response.message);
                    captchaRef.current?.reset();
                    setIsLoading(false);
                }
                dispatch(setError(""));
            }).catch((e: Error) => {
                dispatch(setError(t("misc.genericError")));
                captchaRef.current?.reset();
                setIsLoading(false);
            });
        },
    });

    return (
        <>
            <h1 className='mb-3'>{t("passwordReset.pageTitle")}</h1>
            {!isPasswordResetRequestet ?
                <Form onSubmit={formik.handleSubmit}>
                    <Form.Label className='mt-3'>{t("order.userAuthentication.email")}</Form.Label>
                    <Form.Control type="text" name="email" onChange={formik.handleChange} value={formik.values.email} className={formik.errors.email ? "is-invalid" : ""} />
                    {formik.errors.email ? <Form.Control.Feedback type="invalid">{t(formik.errors.email)}</Form.Control.Feedback> : null}

                    <ReCAPTCHA
                        key={i18n.language.toLowerCase()}
                        hl={i18n.language.toLowerCase()}
                        className="mt-4"
                        sitekey={reCaptchaKey}
                        ref={captchaRef}
                        onExpired={() => { captchaRef.current?.reset(); }}
                        onChange={(t: string | null) => { if (t != null) setCaptchaToken(t); }}
                    />

                    {
                        errorMessage ?
                            <div className="mt-4 error-display">
                                <Form.Control
                                    className="is-invalid"
                                    type="hidden" />
                                <Form.Control.Feedback type="invalid" dangerouslySetInnerHTML={{ __html: t(errorMessage) }}></Form.Control.Feedback>
                            </div> : null
                    }

                    <Button type='submit' variant="primary" className="mt-4" disabled={isLoading} >
                        {t("passwordReset.requestPasswortResetLink")}
                        {isLoading ?
                            <span> <Spinner
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /></span> : null
                        }
                    </Button>
                </Form>
                :
                <div className="alert alert-success" role="alert">
                    {t("passwordReset.resetLinkSentSuccessful").replace("[EMAIL]", formik.values.email)}
                </div>
            }
        </>
    );
}

export default ResetRequestForm;