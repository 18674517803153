import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import ResetForm from '../components/PasswordReset/ResetForm';
import ResetRequestForm from '../components/PasswordReset/ResetRequestForm';
import { useAppDispatch } from '../store/hooks';
import { setLoading } from '../store/slices/loaderSlice';
import Layout from './Layout';

const PasswordReset: React.FunctionComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [resetToken, setResetToken] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ? process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY : "";

    useEffect(() => {
        const passwordResetToken = searchParams.get("token");
        setResetToken(passwordResetToken);
        dispatch(setLoading(false));
    }, []);

    return (
        <Layout>
            <div className="container-md">
                <div className="row">
                    <div className="col-12 pt-4">
                        {!resetToken ? <ResetRequestForm reCaptchaKey={SITE_KEY} /> : <ResetForm reCaptchaKey={SITE_KEY} resetToken={resetToken} />}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PasswordReset;