import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Order } from '../../models/Order';

// Define the initial state using that type
const initialState: Order = {
    id: "", 
    carouselIndex: 0, 
    product: null, 
    professionalId: 0, 
    referenceYear: new Date().getFullYear() - 1, 
    discountCode: null,
    user: null,
    contactInfo: null
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        updateOrder: (state, action: PayloadAction<Partial<Order>>) => {
            Object.assign(state, action.payload);
        },
    },
})

export const { updateOrder } = orderSlice.actions;
export default orderSlice.reducer;
