import React, { useState, ChangeEvent } from 'react'
import { Button } from 'react-bootstrap'
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { updateOrder } from '../../store/slices/orderSlice';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

interface Props {
    toIndex: number
}

const OrderBackButton: React.FunctionComponent<Props> = ({ toIndex }: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const order = useAppSelector((rootState) => rootState.order);
    
    const back = () => {
        let orderState = { ...order };
        orderState.carouselIndex = toIndex;
        dispatch(updateOrder(orderState));
    }

    return (
        <Button variant="danger" onClick={() => back()} className="mt-4 me-3">
            <FontAwesomeIcon icon={faChevronLeft} /> {t("misc.back")}
        </Button>
    );
}

export default OrderBackButton